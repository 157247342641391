<template>
  <section class="lg:py-12 py-6 lg:flex px-8 lg:justify-between lg:items-center" style="background-color: #E8F2F9;">
    <div class="hidden lg:block">
      <img src="../assets/img/Icon ionic-ios-arrow-round-forward.svg" alt="" class="arrowleft" @click="menu2scrollLeft">
    </div>
    <div class="horizontalmenu w-full" ref="horizontalmenu2">
      <router-link v-for="article in news" :key="article.id" :to="`/news/view/${article.id}`" class="bg-white rounded-md shadow inline-block hrmenuitem">
        <div class="rounded-t-md hrbox" style="height: 150px;background-repeat: no-repeat" v-bind:style="{ backgroundImage: 'url(' + baseURL + '/insights/' + article.picture + ')' }"></div>
        <div class="py-3 px-6">
          <p class="text-xs text-gray-500">10,000 small businesses</p>
          <p class="mt-3 text-sm font-semibold text-gray-600">{{article.title}}</p>
        </div>
      </router-link>
    </div>
    <div class="hidden lg:block">
      <img src="../assets/img/Icon ionic-ios-arrow-round-forward.svg" alt="" class="arrowright" @click="menu2scrollRight">
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import baseURL from '@/main'

export default {
  data(){
    return {
      baseURL: baseURL,
      news : []
    }
  },
  methods: {
    menu2scrollLeft(){
      this.$refs.horizontalmenu2.scrollLeft -= 80
    },
    menu2scrollRight(){
      this.$refs.horizontalmenu2.scrollLeft += 80
    },
  },
  created(){
    this.$store.commit('startLoading')
    axios.get(`${baseURL}/api/news`)
    .then(res=>{
      this.news = res.data.result
      this.$store.commit('stopLoading');
    })
    .catch(err=>{
      console.log(err)
      this.$store.commit('stopLoading');
    })
  }

}
</script>

<style scoped>
.horizontalmenu{
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  
}

.horizontalmenu::-webkit-scrollbar
{
  height: 0px;
	background-color: #F5F5F5;
}
.hrmenuitem{
  max-width: 230px;
  flex: 0 0 auto;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  max-height: 260px
}
.hrbox{
  background-size: 100% 100%;
  -moz-transition: all .5s;
  -webkit-transition: all .5s;
  transition: all .5s;
}
.hrbox:hover{
  background-size: 120% 120%;
}
@media only screen and (min-width: 1024px){
  .arrowleft{
    cursor: pointer;
    transform: rotateZ(180deg);
    margin-right: 20px 
  }
  .arrowright{
    cursor: pointer;
    margin-left: 20px
  }
}

</style>