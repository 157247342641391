<template>
  <div id="app">
    <transition name="fade">
      <router-view/>
    </transition>
    <Loading v-if="$store.state.loading"/>
    <Success v-if="$store.state.formSuccess" />
  </div>
</template>
<script>
import Loading from "@/components/Loading"
import Success from "@/components/FormSuccess"
export default {
  components: {
    Loading, Success
  }
}
</script>
<style lang="scss">
#app {
 font-family: 'Fira Sans', sans-serif;
  // scroll-behavior: smooth;
}
:root{
  --bluetext: #2484C6;
  
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
   url(./fonts/Gotham-Font/GothamBook.ttf) format("truetype");
}
@font-face {
  font-family: "Fira Bold";
  src: local("Fira Bold"),
   url(./fonts/fira-sans-cufonfonts/FiraSans-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Gill Bold";
  src: local("Gill Bold"),
   url(./fonts/GillSans/GillSans-Heavy.otf) format("opentype");
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.l-modal::-webkit-scrollbar{
    width: 0
}
.l-modal{
  display: block;
  position: fixed;
  z-index: 10001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0, 0.3);
}
.loading-modal{
  min-height: 350px; 
}
</style>
