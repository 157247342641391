<template>
  <section class="bg-white py-10 px-6 lg:px-16">
    <div class="lg:flex lg:justify-between lg:items-center">
      <div class="text-center lg:text-left lg:w-full lg:mr-6">
        <p class="text-blue-500">Try eTranzact today</p>
        <p class="mt-4 text-2xl lg:text-3xl font-bold" style="color: #1b2837">Industry leaders in payment service solutions and transaction switching, worldwide.</p>
      </div>
      <form @submit.prevent="validate" class="mt-8 bg-white pl-4 border border-solid border-blue-500 border-r-0 rounded flex justify-between items-center lg:w-full lg:ml-6">
        <input type="email" v-model="email" class="w-full mr-3 bg-white outline-none" placeholder="Type in your email address" required>
        <button class="py-2 lg:py-3 ml-3 px-3 lg:px-6 bg-blue-500 text-white rounded border-0">Subscribe</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import baseURL from '@/main'

export default {
  data(){
    return {
      email: ''
    }
  },
  methods:{
    validate(){
      this.$store.commit('startLoading')
      let data = {
        email: this.email
      }
      axios.post(`${baseURL}/api/subscribers`, {}, {params: data})
      .then(()=>{
        this.$store.commit('stopLoading')
        this.$store.commit('showSuccess', 'subscribe')
        this.email = ''
      })
      .catch(()=>{
        this.$store.commit('stopLoading')
        alert('An error occured')
      })
    }
  }
}
</script>

<style>

</style>