import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import VueSocialSharing from 'vue-social-sharing'
import VueWow from 'vue-wow' 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faArrowLeft, faTimes, faSearch, faCheckCircle, faQuestionCircle, faDownload, faTimesCircle, faMapMarker, faBriefcase, faPlayCircle, faAngleDown, faCircle, faArrowRight, faCalendar} from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebookSquare, faInstagram, faTwitter, faLinkedin, faBars, faArrowLeft, faTimes, faSearch, faCheckCircle, faQuestionCircle,faDownload, faTimesCircle,faBriefcase,faMapMarker, faPlayCircle, faAngleDown, faCircle, faArrowRight, faCalendar)
const baseURL = "https://websiteadmin.etranzact.com"

// https://github.com/davtonLMS/etranzact-frontend.git
Vue.use(VueWow)
Vue.use(VueSocialSharing);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

export default baseURL
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
