<template>
   <div class="l-modal">
      <div class="loading-modal w-full">
          <div class="loader bg-white mx-auto">
            <font-awesome-icon icon="times-circle" class="mt-4 mr-4 cursor-pointer text-white text-xl float-right" @click="$store.commit('hideSuccess')"/>
            <p class="text-xl text-white font-bold text-center py-6" style="background-color:#062648">Success</p>
            <div class="text-center pt-6">
              <font-awesome-icon icon="check-circle" class="text-center text-green-500 text-5xl"/>
            </div>
            <p class="mt-6 text-center pb-4 text-xl">{{msg}}</p>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  data(){
    return {
    }
  },
  computed:{
    msg(){
      return this.$store.state.successMsg
    }
  }
}
</script>

<style scoped>
.loader{
  position: relative;
  top: 20px;
  width: 80%;
}
.times{
  top: -50px
}
@media only screen and (min-width: 1024px){
  .loader{
    width: 40%
  }
}
</style>