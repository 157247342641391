import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    formSuccess: false,
    successMsg: 'Success'
  },
  mutations: {
    startLoading(state){
      state.loading = true
    },
    stopLoading(state){
      state.loading = false
    },
    showSuccess(state, val){
      state.formSuccess = true
      if(val == 'subscribe'){
        state.successMsg = "You have been subscribed to our newsletter"
      }else if (val == 'request'){
        state.successMsg = "Your callback request is received and you will be contacted soon by a member of our team"
      }else if (val == 'innovate'){
        state.successMsg = "Idea submitted"
      }
    },
    hideSuccess(state){
      state.formSuccess = false
    },
  },
  actions: {
  },
  modules: {
  }
})
